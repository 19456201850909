var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('vue-toastr',{ref:"mytoast"}),(_vm.isBusy)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"text-center my-3"},[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:void(0);"}},[_c('i',{staticClass:"mdi mdi-loading mdi-spin font-size-20 align-middle me-2"}),_vm._v(" Loading ")])])])]):_vm._e(),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card mb-0"},[_c('b-tabs',{staticClass:"nav-tabs-custom",attrs:{"content-class":"p-4","justified":""}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i',{staticClass:"uil uil-user-circle font-size-20"}),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v("Transaction Overview")])]},proxy:true}])},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-nowrap table-hover mb-0"},[_c('tbody',[_c('tr',[_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":"#"}},[_vm._v("Title")])]),_c('td',[_c('p',[_vm._v(_vm._s(_vm.transaction.title))])])]),_c('tr',[_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":"#"}},[_vm._v("Username")])]),_c('td',[_c('router-link',{staticClass:"d-inline-block text-truncate text-info",staticStyle:{"max-width":"200px"},attrs:{"to":{
                          name: 'user-details',
                          params: { id: _vm.transaction.user_id },
                        }}},[_vm._v(_vm._s(_vm.getUser(_vm.transaction).username))])],1)]),_c('tr',[_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":"#"}},[_vm._v("Amount")])]),_c('td',[_c('p',[_vm._v(_vm._s(_vm.transaction.amount))])])]),_c('tr',[_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":"#"}},[_vm._v("Wallet balance")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(parseFloat( _vm.transaction.wallet_balance ).toLocaleString())+" ")])])]),_c('tr',[_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":"#"}},[_vm._v("Transaction type")])]),_c('td',[_c('div',{staticClass:"badge bg-pill bg-soft-success font-size-12",class:{
                          'bg-soft-danger':
                            _vm.transaction.type === 'outflow',
                          'bg-soft-success':
                            _vm.transaction.type === 'inflow',
                        }},[_vm._v(" "+_vm._s(_vm.transaction.type == 'outflow' ? 'Debit' : 'Credit')+" ")])])]),_c('tr',[_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":"#"}},[_vm._v("Description")])]),_c('td',[_c('p',[_vm._v(_vm._s(_vm.transaction.narration || 'Not available'))])])]),_c('tr',[_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":"#"}},[_vm._v("Remark")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(_vm.getNarration(_vm.transaction.meta))+" ")])])]),_c('tr',[_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":"#"}},[_vm._v("Email")])]),_c('td',[_c('router-link',{staticClass:"d-inline-block text-truncate text-info",staticStyle:{"max-width":"200px"},attrs:{"to":{
                          name: 'user-details',
                          params: { id: _vm.transaction.user_id },
                        }}},[_vm._v(_vm._s(_vm.getUser(_vm.transaction).email))])],1)]),_c('tr',[_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":"#"}},[_vm._v("Reference")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(_vm.transaction.reference || 'Not available')+" ")])])]),_c('tr',[_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":"#"}},[_vm._v("Status")])]),_c('td',[_c('div',{staticClass:"badge bg-pill bg-soft-success font-size-12",class:{
                          'bg-soft-danger':
                            _vm.transaction.status === 'failed' ||
                            _vm.transaction.status === 'reversed',
                          'bg-soft-warning': _vm.transaction.status === 'pending',
                          'bg-soft-success': _vm.transaction.status === 'success',
                        }},[_vm._v(" "+_vm._s(_vm.transaction.status)+" ")])])]),_c('tr',[_c('td',[_c('a',{staticClass:"text-dark",attrs:{"href":"#"}},[_vm._v("Transaction Date/Time")])]),_c('td',[_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.transaction.created_at)))])])])])])])])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }